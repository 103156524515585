import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import store from "./store";
import lanObj from "./json/lan.json"

loadFonts();

const app = createApp(App)
app.use(store)
app.config.globalProperties.$wa = (url) => {
    if (url) window.open(url);
}
app.config.globalProperties.$lan = (key) => {
    try {
        let { lan } = app.config.globalProperties.$store.state
        if (lanObj[lan][key]) {
            return lanObj[lan][key]
        }
    } catch (err) {
        console.error(err);
        return ''
    }
}

app.use(vuetify)
app.mount("#app");





